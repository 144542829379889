import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDsUTSPnhk9fKDVvpcufjlmAQ1WhKUO1Lo',
  authDomain: 'ukrainian-hymns.firebaseapp.com',
  projectId: 'ukrainian-hymns',
  storageBucket: 'ukrainian-hymns.appspot.com',
  messagingSenderId: '980351483497',
  appId: '1:980351483497:web:8370842863146697e32cf8',
  measurementId: 'G-NWMN9DJRR9',
  databaseURL: 'https://ukrainian-hymns-default-rtdb.europe-west1.firebasedatabase.app/',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const realTimeDB = getDatabase(app);
export const analytics = getAnalytics(app);

db.enablePersistence({ synchronizeTabs: true });
