import { isMobile } from 'react-device-detect';

export const STANDARD_DURATION = isMobile ? 0.4 : 0.7;

// in "rem" unit
export const GLOBAL_FONT_SIZE = 1.5;

export const PADDING_FROM_SCREEN_HORIZONTAL_BORDER = '10px';
export const PADDING_FROM_SCREEN_VERTICAL_BORDER = '10px';

export const DEFAULT_LINE_HEIGHT = isMobile ? 1.1 : 1.3;

export const TELEGRAM_BOT_TOKEN = '7372284378:AAEj8j3Nwco4QA-wKTfdEd3ROSxX33x6o2I';

export const TELEGRAM_BOT_TOKEN_CHAT_ID = 431653422;

export const MELODY_MP3_VERSION = 1;

export const CHORDS_IMG_VERSION = 1;

export const USER_HISTORY_HYMN_TIMEOUT = 3000;

export const MAX_HISTORY_SIZE = 1000;

export const HISTORY_LOADING_SIZE = 30;
export const FAVORITES_LOADING_SIZE = 30;

export const WORDS_TOOLTIPS: { [word: string]: string } = {
  'кипер': 'Рослина, цінна своїми ароматними жовтими та білими квітками (Пісн. 1:14).',
  'кіпер': 'Рослина, цінна своїми ароматними жовтими та білими квітками (Пісн. 1:14).',
  'кипера': 'Рослина, цінна своїми ароматними жовтими та білими квітками (Пісн. 1:14).',
  'кіперу': 'Рослина, цінна своїми ароматними жовтими та білими квітками (Пісн. 1:14).',
  'кипером': 'Рослина, цінна своїми ароматними жовтими та білими квітками (Пісн. 1:14).',
  'кіпером': 'Рослина, цінна своїми ароматними жовтими та білими квітками (Пісн. 1:14).',
};

export const TOOLTIP_TITLE_FONT_SIZE = 1;

export const SUP_FONT_SIZE = 1.2;

export const PARAGRAPH_COPY_TIMEOUT = 2000;

export const VERSES_MAX_COUNT = 1000;
export const VERSES_MAX_LOAD_SIZE = 30;

export const GO_TO_SLIDE_BY_TAPING_NUMBERS_TIMOUT_DELAY = 500;

export const PRESENTATION_DEFAULT_ZOOM = 3;
