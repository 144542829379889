import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import 'draft-js/dist/Draft.css'; // Include Draft.js default styles
import 'api';
import AdminsContextProvider from 'api/contexts/AdminsContextProvider';
import withFallback from 'hooks/withFallback';
import AlertContextProvider from 'components/Alert/AlertContextProvider';
import NotificationContextProvider from 'components/Notification/NotificationProvider';
import GlobalResponsiveDialogProvider from 'components/GlobalResponsiveDialog/GlobalResponsiveDialogProvider';
import theme from './theme';
import WorkboxContextProvider, { useWorkboxContext } from './workbox';

const AppWithFallback = withFallback(
  React.lazy(() => import(/* webpackChunkName: "app" */ './App')),
);

export function AppWrapper(): React.ReactElement {
  const { updateAvailable, updateRejected, workbox, setUpdateRejected, setUpdateAvailable } =
    useWorkboxContext();

  const onUpdateAccepted = () => {
    if (workbox) {
      workbox.messageSkipWaiting();
      setUpdateAvailable(false);
    }
  };

  const onUpdateRejected = () => setUpdateRejected(true);

  return (
    <AppWithFallback
      updateAvailable={updateAvailable && !updateRejected}
      onUpdateAccepted={onUpdateAccepted}
      onUpdateRejected={onUpdateRejected}
    />
  );
}

const AppWrapperWithProviders: React.FC = () => {
  return (
    <WorkboxContextProvider>
      <GlobalResponsiveDialogProvider>
        <AlertContextProvider>
          <NotificationContextProvider>
            <AdminsContextProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppWrapper />
              </ThemeProvider>
            </AdminsContextProvider>
          </NotificationContextProvider>
        </AlertContextProvider>
      </GlobalResponsiveDialogProvider>
    </WorkboxContextProvider>
  );
};

window.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(<AppWrapperWithProviders />);
  container?.removeAttribute('style');
});
