export enum MainHymnsAvailableLanguages {
  // cases should be appropriate with textUa, textRu, etc.
  UA = 'Ua',
  RU = 'Ru',
}

export interface MainHymnParagraphLineAccord {
  id: string;
  chord: string;
  position: number;
}

export interface MainHymnParagraphLine {
  id: string;
  textUa?: string;
  textRu?: string;
  accordsUa?: MainHymnParagraphLineAccord[];
  accordsRu?: MainHymnParagraphLineAccord[];
}

export interface MainHymnParagraph {
  id: string;
  topNoteUa?: string;
  topNoteRu?: string;
  // like "repeat twice"
  bottomNoteUa?: string;
  bottomNoteRu?: string;
  // like "1", "2", "7b" (in case with a bible verse)
  leftNoteUa?: string;
  leftNoteRu?: string;
  // for choruses for example
  hasLeftMargin?: boolean;
  // the field is needed to not duplicate lines if there will be repeated choruses
  isMainChorus?: boolean;
  isRepeated?: boolean;
  // in case when isRepeated === true this field isn't present
  lines?: MainHymnParagraphLine[];
}

export interface MainHymn {
  docId: string;
  number: number;
  meter: string;
  // only 244 hymn doesn't have eng version
  engNumber?: number;
  // this filed is needed to download mp3 melody files by
  // `${sharedMelodyEngNumber}_multipleSource` path in firebase storage
  sharedMelodyEngNumber?: number;
  capoHint?: string;
  // TODO: remove this optional sign when all main hymns are available and find all references where we use "category?."
  category?: string;
  // TODO: remove this optional sign when all main hymns are available and find all references where we use "subcategory?."
  subcategory?: string;
  // TODO: remove this optional sign when all main hymns are available and find all references where we use "paragraphs?."
  paragraphs?: MainHymnParagraph[];
  bottomNote?: string;
  topNote?: string;
  extraInfo?: string;
  scriptures?: string;
  editMark?: '*' | '†';
  moreMelodies?: string[];
}

export type MainHymns = MainHymn[];

export interface MainHymnCategoriesMapped {
  [categoryId: string]: MainHymnCategory;
}

export interface MainHymnCategory {
  title: string;
}

export type MainHymnSubcategory = MainHymnCategory;
export type MainHymnSubcategoriesMapped = MainHymnCategoriesMapped;

export interface MainHymnsUserParams {
  showAccords?: boolean;
  preferredLanguage?: MainHymnsAvailableLanguages;
}

// just need to count all boolean fields in the Paragraph type. The case is important.
// it's needed for typing of toggleParagraphFieldValue() function
export enum MainHymnParagraphBooleanFields {
  HAS_LEFT_MARGIN = 'hasLeftMargin',
  IS_MAIN_CHORUS = 'isMainChorus',
  IS_REPEATED = 'isRepeated',
}
