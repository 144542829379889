export enum LocalStorageItems {
  LAST_OPENED_MAIN_HYMN = 'lastOpenedMainHymn',
  LAST_OPENED_YOUTH_HYMN = 'lastOpenedYouthHymn',
  LAST_OPENED_ADMIN_MAIN_HYMN = 'lastOpenedAdminMainHymn',
  LAST_OPENED_ADMIN_YOUTH_HYMN = 'lastOpenedAdminYouthHymn',
  IS_SERVICE_WORKER_DISABLED = 'isServiceWorkerDisabled',
  IS_UPDATE_IN_BACKGROUND = 'isUpdateInBackground',
  SHOW_TUTORIAL = 'showTutorial',
  ADMIN_PANEL_MAIN_HYMNS = 'adminPanelMainHymns',
  ADMIN_PANEL_YOUTH_HYMNS = 'adminPanelYouthHymns',
  YOUTH_HYMNS_USER_PARAMS = 'youthHymnsUserParams',
  MAIN_HYMNS_USER_PARAMS = 'mainHymnsUserParams',
  USER_FAVORITES = 'userFavorites',
  USER_HISTORY = 'userHistory',
  APP_DOWNLOAD = 'appDownload',
  YOUTH_HYMNS_COLLECTION = 'youthHymnCollection',
  MAIN_HYMNS_COLLECTION = 'mainHymnsCollection',
  MAIN_HYMNS_CATEGORIES_COLLECTION = 'mainHymnsCategoriesCollection',
  MAIN_HYMNS_SUBCATEGORIES_COLLECTION = 'mainHymnsSubcategoriesCollection',
  ADMIN_APP_VERSION_CHANGING = 'adminAppVersionChanging',
  SHOW_PRESENTATION_TUTORIAL = 'showPresentationTutorial',
}
